.sign-up-container {
  display: flex;
  flex-direction: column;
  width: 380px;

  h2 {
    margin: 10px 0;
  }

  .buttons-group {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
  }
}
