body {
  margin: 0;
  padding: 20px 40px;
  font-family: "Maven Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

#root {
  width: 900px;
}

a {
  text-decoration: none;
  color: black;
}

* {
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}
