.product-card-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: auto;
  align-items: center;
  position: relative;
  border: 1px solid black;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  button {
    width: 80%;
    opacity: 0;
    position: absolute;
    top: 60%;

    transition: opacity 0.1s;
  }

  &:hover {
    img {
      opacity: 0.8;
    }

    button {
      opacity: 0.85;
      display: flex;
    }
  }

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    padding: 20px;

    .name {
      width: 60%;
      text-align: start;
    }

    .price {
      width: 30%;
      text-align: end;
    }
  }
}
